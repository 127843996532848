import { type DtoProgression } from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { cn } from '../../../../utils/cn';
import { ListViewIcon } from '../../../icons/ListViewIcon';
import { assessmentScoreToColor } from '../assessmentScoreToColor';
import { useTriggerAssessmentDetailsModal } from '../Modals/AssessmentDetailsModal';

interface AssessmentScoreButtonProps {
  progression?: DtoProgression;
  learnerName?: string;
  className?: string;
}

export function AssessmentScoreButton({
  progression,
  learnerName,
  className,
}: AssessmentScoreButtonProps) {
  const assessmentScore = progression?.assessmentResult?.score;
  const triggerAssessmentDetailsModal = useTriggerAssessmentDetailsModal({
    progression,
    learnerName,
  });

  const handleClick = useLiveCallback((e: React.MouseEvent) => {
    if (progression && assessmentScore !== undefined) {
      e.stopPropagation();
      triggerAssessmentDetailsModal();
    }
  });

  const hasAssessment = progression && assessmentScore !== undefined;

  return (
    <div
      className={cn(
        'inline-flex items-center gap-1.5 px-1.5 rounded-md text-sm font-medium',
        hasAssessment
          ? 'bg-secondary hover:bg-lp-gray-006 cursor-pointer border border-lp-gray-003 transition-colors'
          : '',
        assessmentScoreToColor(assessmentScore),
        className
      )}
      onClick={handleClick}
    >
      {assessmentScore !== undefined ? (
        <>
          <span>{`${Math.round(assessmentScore * 100)}%`}</span>
          <ListViewIcon className='opacity-70 text-icon-gray' />
        </>
      ) : (
        'N/A'
      )}
    </div>
  );
}
