import { type ReactNode, useCallback, useState } from 'react';

import {
  type DtoProgression,
  type ModelsBlockAssessmentResult,
  type ModelsBlockOutput,
} from '@lp-lib/api-service-client/public';
import {
  BlockType,
  blockTypeToName,
  BlockTypeV2,
  blockTypeV2fromEnumsBlockType,
} from '@lp-lib/game';
import {
  pickBlockOutputSchema,
  validateBlockAssessmentOutputs,
} from '@lp-lib/game/src/block-outputs';

import { getLogger } from '../../../../logger/logger';
import { useAwaitFullScreenConfirmCancelModal } from '../../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../../ConfirmCancelModalContext/ModalWrapper';
import { blockTypePlayable } from '../../../GameV2/blocks/block-grade-result';
import { rawDrawToWinBlockOutputSchema } from '../../../GameV2/blocks/DrawToWin/outputs';
import { rawFillInTheBlanksBlockOutputSchema } from '../../../GameV2/blocks/FillInTheBlanks/outputs';
import { rawHiddenPictureBlockOutputSchema } from '../../../GameV2/blocks/HiddenPicture/outputs';
import { rawJeopardyBlockOutputSchema } from '../../../GameV2/blocks/Jeopardy/outputs';
import { rawMemoryMatchBlockOutputSchema } from '../../../GameV2/blocks/Match/outputs';
import { rawMultipleChoiceBlockOutputSchema } from '../../../GameV2/blocks/MultipleChoice/outputs';
import { rawQuestionBlockOutputSchema } from '../../../GameV2/blocks/Question/outputs';
import { rawRoleplayBlockOutputSchema } from '../../../GameV2/blocks/Roleplay/outputs';
import { rawScenarioBlockOutputSchema } from '../../../GameV2/blocks/Scenario/outputs';
import { rawSwipeToWinBlockOutputSchema } from '../../../GameV2/blocks/SwipeToWin/outputs';
import { SparkBlockIcon } from '../../../icons/Block';
import { GreenCheckIcon } from '../../../icons/GreenCheckIcon';
import { XIcon } from '../../../icons/XIcon';

/**
 * A reusable card container component for assessment details
 */
interface DetailCardProps {
  /** Content to render inside the card */
  children: ReactNode;
  /** Optional background color class, defaults to none */
  bgColorClass?: string;
  /** Optional border color class, defaults to border-gray-500/30 */
  borderColorClass?: string;
}

function DetailCard({
  children,
  bgColorClass = 'bg-main-layer',
  borderColorClass = 'border-gray-500/30',
}: DetailCardProps) {
  return (
    <div
      className={`rounded p-4 mb-4 border ${borderColorClass} ${bgColorClass}`}
    >
      {children}
    </div>
  );
}

interface AssessmentDetailsModalProps {
  onClose: () => void;
  onConfirm: () => void;
  progression?: DtoProgression;
  learnerName?: string;
}

function InvalidOutputsDetail({ blockType }: { blockType: BlockType }) {
  return (
    <DetailCard
      bgColorClass='bg-red-900/20'
      borderColorClass='border-red-500/50'
    >
      <div className='flex flex-row gap-4 justify-between mb-2'>
        <div className='flex flex-row gap-2 text-icon-gray'>
          <SparkBlockIcon
            className='flex-shrink-0 w-6 h-6'
            blockType={blockType}
          />
          {blockTypeToName(blockType)}
        </div>
        <div>
          <XIcon className='w-6 h-6 fill-current text-red-500' />
        </div>
      </div>

      <div className='flex flex-col gap-3'>
        <p className='text-lg font-medium text-white'>
          We couldn't display this question's results
        </p>

        <p className='text-red-300'>
          This might happen if the question was updated after this learner's
          session.{' '}
        </p>

        <div className='mt-2 bg-black/30 p-3 rounded-md'>
          <p className='text-sm text-icon-gray'>
            Your learners assessment has been saved, but we can't show the
            details for this specific question.
          </p>

          <p className='text-sm text-icon-gray mt-2'>
            If you see this message frequently, please contact support and
            mention activity type:
            <br />
            <br />
            <span className='text-white font-mono'>
              {blockTypeToName(blockType)}
            </span>
          </p>
        </div>
      </div>
    </DetailCard>
  );
}

function BlockResultDetail({
  blockResult,
}: {
  blockResult: ModelsBlockAssessmentResult;
}) {
  if (!blockTypePlayable(blockResult.blockType)) {
    return null;
  }
  const blockType = blockTypeV2fromEnumsBlockType(blockResult.blockType);
  const outputs = blockResult.outputs || {};

  // If not a playable block type, don't render anything

  switch (blockType) {
    case BlockTypeV2.MULTIPLE_CHOICE: {
      return <MultipleChoiceBlockDetail outputs={outputs} />;
    }
    case BlockTypeV2.QUESTION: {
      return <QuestionBlockDetail outputs={outputs} />;
    }
    case BlockTypeV2.MATCH: {
      return <MemoryMatchBlockDetail outputs={outputs} />;
    }
    case BlockTypeV2.ROLEPLAY: {
      return <RoleplayBlockDetail outputs={outputs} />;
    }
    case BlockTypeV2.DRAW_TO_WIN: {
      return <DrawToWinBlockDetail outputs={outputs} />;
    }
    case BlockTypeV2.HIDDEN_PICTURE: {
      return <HiddenPictureBlockDetail outputs={outputs} />;
    }
    case BlockTypeV2.JEOPARDY: {
      return <JeopardyBlockDetail outputs={outputs} />;
    }
    case BlockTypeV2.FILL_IN_THE_BLANKS: {
      return <FillInTheBlanksBlockDetail outputs={outputs} />;
    }
    case BlockTypeV2.SWIPE_TO_WIN: {
      return <SwipeToWinBlockDetail outputs={outputs} />;
    }
    case BlockTypeV2.SCENARIO: {
      return <ScenarioBlockDetail outputs={outputs} />;
    }
    default:
      return (
        <DetailCard>
          <h3 className='text-lg font-semibold mb-2'>{blockType} Question</h3>
          <p className='mb-2'>
            Points: {outputs.points?.value || 0}/
            {outputs.totalPoints?.value || 0}
          </p>
        </DetailCard>
      );
  }
}

function BlockDetailHeader(props: { blockType: BlockType; correct: boolean }) {
  return (
    <div className='flex flex-row gap-4 justify-between mb-2'>
      <div className='flex flex-row gap-2 text-icon-gray'>
        <SparkBlockIcon
          className='flex-shrink-0 w-6 h-6'
          blockType={props.blockType}
        />
        {blockTypeToName(props.blockType)}
      </div>
      <div>
        {props.correct ? (
          <GreenCheckIcon className='w-6 h-6 fill-current text-green-500' />
        ) : (
          <XIcon className='w-6 h-6 fill-current text-red-500' />
        )}
      </div>
    </div>
  );
}

function MultipleChoiceBlockDetail({
  outputs,
}: {
  outputs: Record<string, ModelsBlockOutput>;
}) {
  const logger = getLogger().scoped('multiple-choice-block-detail');
  const subSchema = pickBlockOutputSchema(rawMultipleChoiceBlockOutputSchema, [
    'question',
    'answer',
    'choice',
    'grade',
  ]);
  const res = validateBlockAssessmentOutputs(outputs, subSchema);

  if (!res.success) {
    logger.error('Validation failed', {
      error: res.error,
      availableKeys: Object.keys(outputs),
      outputs: JSON.stringify(outputs, null, 2),
      expectedSchema: JSON.stringify(subSchema, null, 2),
    });
    return <InvalidOutputsDetail blockType={BlockType.MULTIPLE_CHOICE} />;
  }

  const typedOutputs = res.data;
  const isCorrect = typedOutputs.grade.value === 'correct';

  return (
    <DetailCard>
      <BlockDetailHeader
        blockType={BlockType.MULTIPLE_CHOICE}
        correct={isCorrect}
      />
      <p className='mb-4 text-lg'>{typedOutputs.question.value}</p>

      <div className='grid grid-cols-4 gap-x-2 gap-y-1'>
        <div className='text-icon-gray'>Correct Answer:</div>
        <div className='col-span-3'>{typedOutputs.answer.value}</div>

        {!isCorrect && (
          <>
            <div className='text-icon-gray'>Your Answer:</div>
            <div className='col-span-3 text-red-500'>
              {typedOutputs.choice.value}
            </div>
          </>
        )}
      </div>
    </DetailCard>
  );
}

function QuestionBlockDetail({
  outputs,
}: {
  outputs: Record<string, ModelsBlockOutput>;
}) {
  const logger = getLogger().scoped('question-block-detail');
  const subSchema = pickBlockOutputSchema(rawQuestionBlockOutputSchema, [
    'question',
    'answer',
    'submission',
    'grade',
  ]);
  const res = validateBlockAssessmentOutputs(outputs, subSchema);
  if (!res.success) {
    logger.error('Validation failed', {
      error: res.error,
      availableKeys: Object.keys(outputs),
      outputs: JSON.stringify(outputs, null, 2),
      expectedSchema: JSON.stringify(subSchema, null, 2),
    });
    return <InvalidOutputsDetail blockType={BlockType.QUESTION} />;
  }

  const typedOutputs = res.data;
  const isCorrect = typedOutputs.grade.value === 'correct';

  return (
    <DetailCard>
      <BlockDetailHeader blockType={BlockType.QUESTION} correct={isCorrect} />
      <p className='mb-4 text-lg'>{typedOutputs.question.value}</p>

      <div className='grid grid-cols-4 gap-x-2 gap-y-1'>
        <div className='text-icon-gray'>Correct Answer:</div>
        <div className='col-span-3'>{typedOutputs.answer.value}</div>

        <div className='text-icon-gray'>Your Answer:</div>
        <div className={`col-span-3 ${!isCorrect ? 'text-red-500' : ''}`}>
          {typedOutputs.submission.value}
        </div>
      </div>
    </DetailCard>
  );
}

function MemoryMatchBlockDetail({
  outputs,
}: {
  outputs: Record<string, ModelsBlockOutput>;
}) {
  const logger = getLogger().scoped('memory-match-block-detail');
  const subSchema = pickBlockOutputSchema(rawMemoryMatchBlockOutputSchema, [
    'question',
    'matchedCount',
    'cardPairsCount',
    'mismatchCount',
  ]);
  const res = validateBlockAssessmentOutputs(outputs, subSchema);

  if (!res.success) {
    logger.error('Validation failed', {
      error: res.error,
      availableKeys: Object.keys(outputs),
      outputs: JSON.stringify(outputs, null, 2),
      expectedSchema: JSON.stringify(subSchema, null, 2),
    });
    return <InvalidOutputsDetail blockType={BlockType.MEMORY_MATCH} />;
  }

  const typedOutputs = res.data;
  // For match blocks, success is based on having some matched pairs
  const hasMatches = (typedOutputs.matchedCount.value || 0) > 0;

  return (
    <DetailCard>
      <BlockDetailHeader
        blockType={BlockType.MEMORY_MATCH}
        correct={hasMatches}
      />
      <p className='mb-4 text-lg'>{typedOutputs.question.value}</p>

      <div className='grid grid-cols-4 gap-x-2 gap-y-1'>
        <div className='text-icon-gray'>Matched Pairs:</div>
        <div className='col-span-3'>
          {typedOutputs.matchedCount.value}/{typedOutputs.cardPairsCount.value}
        </div>

        <div className='text-icon-gray'>Mismatches:</div>
        <div className='col-span-3'>{typedOutputs.mismatchCount.value}</div>
      </div>
    </DetailCard>
  );
}

function RoleplayBlockDetail({
  outputs,
}: {
  outputs: Record<string, ModelsBlockOutput>;
}) {
  const logger = getLogger().scoped('roleplay-block-detail');
  const [isPromptExpanded, setIsPromptExpanded] = useState(false);
  const subSchema = pickBlockOutputSchema(rawRoleplayBlockOutputSchema, [
    'systemPrompt',
    'result',
  ]);
  const res = validateBlockAssessmentOutputs(outputs, subSchema);

  if (!res.success) {
    logger.error('Validation failed', {
      error: res.error,
      availableKeys: Object.keys(outputs),
      outputs: JSON.stringify(outputs, null, 2),
      expectedSchema: JSON.stringify(subSchema, null, 2),
    });
    return <InvalidOutputsDetail blockType={BlockType.ROLEPLAY} />;
  }

  const typedOutputs = res.data;
  const isSucceeded = typedOutputs.result.value === 'succeeded';

  return (
    <DetailCard>
      <BlockDetailHeader blockType={BlockType.ROLEPLAY} correct={isSucceeded} />

      <div className='mb-4'>
        <div className='flex justify-between items-center'>
          <div className='text-lg font-medium text-white'>Scenario</div>
          <button
            type='button'
            className='text-sm text-slate-400 hover:text-slate-300 focus:outline-none'
            onClick={() => setIsPromptExpanded(!isPromptExpanded)}
          >
            {isPromptExpanded ? 'Collapse' : 'Expand'}
          </button>
        </div>
        {isPromptExpanded ? (
          <pre className='mt-2 whitespace-pre-wrap text-white'>
            {typedOutputs.systemPrompt.value}
          </pre>
        ) : (
          <div className='mt-2 relative'>
            <pre className='whitespace-pre-wrap text-white max-h-24 overflow-hidden'>
              {typedOutputs.systemPrompt.value}
            </pre>
            <div className='absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-t from-main-layer to-transparent'></div>
          </div>
        )}
      </div>

      <div className='grid grid-cols-4 gap-x-2 gap-y-1'>
        <div className='text-icon-gray'>Result:</div>
        <div
          className={`col-span-3 ${
            isSucceeded ? 'text-green-500' : 'text-red-500'
          }`}
        >
          {isSucceeded ? 'Passed' : 'Failed'}
        </div>
      </div>
    </DetailCard>
  );
}

function DrawToWinBlockDetail({
  outputs,
}: {
  outputs: Record<string, ModelsBlockOutput>;
}) {
  const logger = getLogger().scoped('draw-to-win-block-detail');
  const subSchema = pickBlockOutputSchema(rawDrawToWinBlockOutputSchema, [
    'question',
    'grade',
  ]);
  const res = validateBlockAssessmentOutputs(outputs, subSchema);

  if (!res.success) {
    logger.error('Validation failed', {
      error: res.error,
      availableKeys: Object.keys(outputs),
      outputs: JSON.stringify(outputs, null, 2),
      expectedSchema: JSON.stringify(subSchema, null, 2),
    });
    return <InvalidOutputsDetail blockType={BlockType.DRAW_TO_WIN} />;
  }

  const typedOutputs = res.data;
  const isPassed = typedOutputs.grade.value === 'correct';

  return (
    <DetailCard>
      <BlockDetailHeader blockType={BlockType.DRAW_TO_WIN} correct={isPassed} />
      <p className='mb-4 text-lg'>{typedOutputs.question.value}</p>

      <div className='grid grid-cols-4 gap-x-2 gap-y-1'>
        <div className='text-icon-gray'>Result:</div>
        <div
          className={`col-span-3 ${
            isPassed ? 'text-green-500' : 'text-red-500'
          }`}
        >
          {isPassed ? 'Passed' : 'Failed'}
        </div>
      </div>
    </DetailCard>
  );
}

function HiddenPictureBlockDetail({
  outputs,
}: {
  outputs: Record<string, ModelsBlockOutput>;
}) {
  const logger = getLogger().scoped('hidden-picture-block-detail');
  const subSchema = pickBlockOutputSchema(rawHiddenPictureBlockOutputSchema, [
    'question',
    'foundCount',
    'spotsCount',
  ]);
  const res = validateBlockAssessmentOutputs(outputs, subSchema);

  if (!res.success) {
    logger.error('Validation failed', {
      error: res.error,
      availableKeys: Object.keys(outputs),
      outputs: JSON.stringify(outputs, null, 2),
      expectedSchema: JSON.stringify(subSchema, null, 2),
    });
    return <InvalidOutputsDetail blockType={BlockType.HIDDEN_PICTURE} />;
  }

  const typedOutputs = res.data;
  // Found at least some items
  const foundSome = (typedOutputs.foundCount.value || 0) > 0;

  return (
    <DetailCard>
      <BlockDetailHeader
        blockType={BlockType.HIDDEN_PICTURE}
        correct={foundSome}
      />
      <p className='mb-4 text-lg'>{typedOutputs.question.value}</p>

      <div className='grid grid-cols-4 gap-x-2 gap-y-1'>
        <div className='text-icon-gray'>Items Found:</div>
        <div className='col-span-3'>
          {typedOutputs.foundCount.value}/{typedOutputs.spotsCount.value}
        </div>
      </div>
    </DetailCard>
  );
}

function JeopardyBlockDetail({
  outputs,
}: {
  outputs: Record<string, ModelsBlockOutput>;
}) {
  const logger = getLogger().scoped('jeopardy-block-detail');
  const subSchema = pickBlockOutputSchema(rawJeopardyBlockOutputSchema, [
    'correctCount',
    'incorrectCount',
    'correctCategories',
  ]);
  const res = validateBlockAssessmentOutputs(outputs, subSchema);

  if (!res.success) {
    logger.error('Validation failed', {
      error: res.error,
      availableKeys: Object.keys(outputs),
      outputs: JSON.stringify(outputs, null, 2),
      expectedSchema: JSON.stringify(subSchema, null, 2),
    });
    return <InvalidOutputsDetail blockType={BlockType.JEOPARDY} />;
  }

  const typedOutputs = res.data;
  // If they have more correct than incorrect answers
  const moreCorrectThanIncorrect =
    (typedOutputs.correctCount.value || 0) >
    (typedOutputs.incorrectCount.value || 0);

  return (
    <DetailCard>
      <BlockDetailHeader
        blockType={BlockType.JEOPARDY}
        correct={moreCorrectThanIncorrect}
      />

      <div className='grid grid-cols-4 gap-x-2 gap-y-1'>
        <div className='text-icon-gray'>Correct Answers:</div>
        <div className='col-span-3'>{typedOutputs.correctCount.value}</div>

        <div className='text-icon-gray'>Incorrect Answers:</div>
        <div className='col-span-3 text-red-500'>
          {typedOutputs.incorrectCount.value}
        </div>

        {typedOutputs.correctCategories.value && (
          <>
            <div className='text-icon-gray'>Correct Categories:</div>
            <div className='col-span-3'>
              {typedOutputs.correctCategories.value}
            </div>
          </>
        )}
      </div>
    </DetailCard>
  );
}

function FillInTheBlanksBlockDetail({
  outputs,
}: {
  outputs: Record<string, ModelsBlockOutput>;
}) {
  const logger = getLogger().scoped('fill-in-the-blanks-block-detail');
  const subSchema = pickBlockOutputSchema(rawFillInTheBlanksBlockOutputSchema, [
    'sentence',
    'correctCount',
    'blanksCount',
  ]);
  const res = validateBlockAssessmentOutputs(outputs, subSchema);

  if (!res.success) {
    logger.error('Validation failed', {
      error: res.error,
      availableKeys: Object.keys(outputs),
      outputs: JSON.stringify(outputs, null, 2),
      expectedSchema: JSON.stringify(subSchema, null, 2),
    });
    return <InvalidOutputsDetail blockType={BlockType.FILL_IN_THE_BLANKS} />;
  }

  const typedOutputs = res.data;
  const isSuccessful =
    (typedOutputs.correctCount.value || 0) >=
    (typedOutputs.blanksCount.value || 0) / 2;

  const incorrectBlanks =
    (typedOutputs.blanksCount.value || 0) -
    (typedOutputs.correctCount.value || 0);

  return (
    <DetailCard>
      <BlockDetailHeader
        blockType={BlockType.FILL_IN_THE_BLANKS}
        correct={isSuccessful}
      />
      <p className='mb-4 text-lg'>{typedOutputs.sentence.value}</p>

      <div className='grid grid-cols-4 gap-x-2 gap-y-1'>
        <div className='text-icon-gray'>Correct Blanks:</div>
        <div className='col-span-3'>
          {typedOutputs.correctCount.value}/{typedOutputs.blanksCount.value}
        </div>

        {incorrectBlanks > 0 && (
          <>
            <div className='text-icon-gray'>Incorrect Blanks:</div>
            <div className='col-span-3 text-red-500'>{incorrectBlanks}</div>
          </>
        )}
      </div>
    </DetailCard>
  );
}

function SwipeToWinBlockDetail({
  outputs,
}: {
  outputs: Record<string, ModelsBlockOutput>;
}) {
  const logger = getLogger().scoped('swipe-to-win-block-detail');
  const subSchema = pickBlockOutputSchema(rawSwipeToWinBlockOutputSchema, [
    'question',
    'matchedCount',
    'cardPairsCount',
  ]);
  const res = validateBlockAssessmentOutputs(outputs, subSchema);

  if (!res.success) {
    logger.error('Validation failed', {
      error: res.error,
      availableKeys: Object.keys(outputs),
      outputs: JSON.stringify(outputs, null, 2),
      expectedSchema: JSON.stringify(subSchema, null, 2),
    });
    return <InvalidOutputsDetail blockType={BlockType.SWIPE_TO_WIN} />;
  }

  const typedOutputs = res.data;
  const hasMatches = (typedOutputs.matchedCount.value || 0) > 0;

  const unmatchedPairs =
    (typedOutputs.cardPairsCount.value || 0) -
    (typedOutputs.matchedCount.value || 0);

  return (
    <DetailCard>
      <BlockDetailHeader
        blockType={BlockType.SWIPE_TO_WIN}
        correct={hasMatches}
      />
      <p className='mb-4 text-lg'>{typedOutputs.question.value}</p>

      <div className='grid grid-cols-4 gap-x-2 gap-y-1'>
        <div className='text-icon-gray'>Matched Pairs:</div>
        <div className='col-span-3'>
          {typedOutputs.matchedCount.value}/{typedOutputs.cardPairsCount.value}
        </div>

        {unmatchedPairs > 0 && (
          <>
            <div className='text-icon-gray'>Unmatched Pairs:</div>
            <div className='col-span-3 text-red-500'>{unmatchedPairs}</div>
          </>
        )}
      </div>
    </DetailCard>
  );
}

function ScenarioBlockDetail({
  outputs,
}: {
  outputs: Record<string, ModelsBlockOutput>;
}) {
  const logger = getLogger().scoped('scenario-block-detail');
  const subSchema = pickBlockOutputSchema(rawScenarioBlockOutputSchema, [
    'question',
    'result',
    'thingsSaidCorrectly',
    'thingsAvoidedSaying',
    'transcript',
    'correctCount',
    'incorrectCount',
  ]);
  const res = validateBlockAssessmentOutputs(outputs, subSchema);

  if (!res.success) {
    logger.error('Validation failed', {
      error: res.error,
      availableKeys: Object.keys(outputs),
      outputs: JSON.stringify(outputs, null, 2),
      expectedSchema: JSON.stringify(subSchema, null, 2),
    });
    return <InvalidOutputsDetail blockType={BlockType.SCENARIO} />;
  }

  const typedOutputs = res.data;
  const isSucceeded = typedOutputs.result.value === 'succeeded';
  const correctCount = typedOutputs.correctCount.value;
  const incorrectCount = typedOutputs.incorrectCount.value;

  // Helper function to parse and display points with status indicators
  const renderPoints = (pointsText: string) => {
    if (!pointsText) return null;

    // Split the text into lines for proper formatting
    const lines = pointsText.split('\n');

    return (
      <div className='whitespace-pre-wrap'>
        {lines.map((line, index) => {
          // Try to split by colon if present
          const parts = line.split(':');

          if (parts.length > 1) {
            // If there's a colon, check the status part for "passed" or "failed"
            const [pointDescription, statusPart] = [
              parts[0],
              parts.slice(1).join(':').trim(),
            ];
            const hasPassedStatus = statusPart.toLowerCase().includes('passed');
            const hasFailedStatus = statusPart.toLowerCase().includes('failed');

            return (
              <div key={index} className='mb-1'>
                {pointDescription}:&nbsp;
                <span
                  className={
                    hasPassedStatus
                      ? 'text-green-500 font-medium'
                      : hasFailedStatus
                      ? 'text-red-500 font-medium'
                      : ''
                  }
                >
                  {statusPart}
                </span>
              </div>
            );
          } else {
            // If no colon, just display the line in default color
            return (
              <div key={index} className='mb-1'>
                {line}
              </div>
            );
          }
        })}
      </div>
    );
  };

  return (
    <DetailCard>
      <div className='flex flex-row gap-4 justify-between mb-2'>
        <div className='flex flex-row gap-2 text-icon-gray'>
          <SparkBlockIcon
            className='flex-shrink-0 w-6 h-6'
            blockType={BlockType.SCENARIO}
          />
          Scenario
        </div>
        <div>
          {isSucceeded ? (
            <GreenCheckIcon className='w-6 h-6 fill-current text-green-500' />
          ) : (
            <XIcon className='w-6 h-6 fill-current text-red-500' />
          )}
        </div>
      </div>

      <p className='mb-4 text-lg'>{typedOutputs.question.value}</p>

      <div className='grid grid-cols-4 gap-x-2 gap-y-4'>
        {typedOutputs.thingsSaidCorrectly.value && (
          <>
            <div className='text-icon-gray'>Breakdown:</div>
            <div className='col-span-3'>
              {renderPoints(typedOutputs.thingsSaidCorrectly.value)}
            </div>
          </>
        )}

        {typedOutputs.thingsAvoidedSaying.value && (
          <>
            <div className='text-icon-gray'>Avoid Points:</div>
            <div className='col-span-3'>
              {renderPoints(typedOutputs.thingsAvoidedSaying.value)}
            </div>
          </>
        )}

        <div className='text-icon-gray'>Result:</div>
        <div
          className={`col-span-3 ${
            isSucceeded ? 'text-green-500' : 'text-red-500'
          }`}
        >
          {Math.round((correctCount / (correctCount + incorrectCount)) * 100)}%
        </div>

        {typedOutputs.transcript.value && (
          <>
            <div className='text-icon-gray'>Transcript:</div>
            <div className='col-span-3'>
              <pre className='whitespace-pre-wrap'>
                {typedOutputs.transcript.value}
              </pre>
            </div>
          </>
        )}
      </div>
    </DetailCard>
  );
}
export function AssessmentDetailsModal({
  onConfirm,
  onClose,
  progression,
  learnerName,
}: AssessmentDetailsModalProps) {
  if (!progression) return null;
  const { assessmentResult } = progression;

  const total = assessmentResult?.score ?? 0;
  const results = assessmentResult?.blockResults ?? [];

  return (
    <ModalWrapper
      borderStyle='gray'
      containerClassName='w-[800px] h-auto max-h-[90vh] overflow-y-auto'
      innerClassName='bg-modal'
      onClose={onClose}
    >
      <div className='px-8 pt-8 pb-8 text-white flex flex-col justify-between gap-6'>
        {/* Title and Subtitle */}
        <div className='text-left'>
          <h1 className='text-2xl font-bold pb-8'>Assessment Details</h1>
          <div className='flex justify-between gap-4 text-lg mt-2'>
            <span>{learnerName}</span>
            <span>
              <span className='text-icon-gray'>Overall Score</span>
              &nbsp;
              {Math.round(total * 100)}%
            </span>
          </div>
        </div>

        {/* Assessment Results Section */}
        <div className='mt-4'>
          <h2 className='text-xl font-bold mb-4'>Question Responses</h2>
          {results.length > 0 ? (
            <div className='space-y-4'>
              {results.map((blockResult) => (
                <BlockResultDetail
                  key={blockResult.blockId}
                  blockResult={blockResult}
                />
              ))}
            </div>
          ) : (
            <p className='text-gray-400'>No assessment results available.</p>
          )}
        </div>

        {/* Get Started Button */}
        <div className='flex justify-center mt-6'>
          <button
            type='button'
            onClick={onConfirm}
            className='px-24 py-2 btn btn-primary bg-cyan-500 text-white hover:bg-cyan-600 rounded-lg'
          >
            Close
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}

interface TriggerAssessmentDetailsModalProps {
  progression?: DtoProgression;
  learnerName?: string;
}

export function useTriggerAssessmentDetailsModal({
  progression,
  learnerName,
}: TriggerAssessmentDetailsModalProps) {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(() => {
    return triggerModal({
      kind: 'custom',
      containerClassName: 'bg-lp-black-002',
      element: (modalProps) => (
        <AssessmentDetailsModal
          onClose={modalProps.internalOnCancel}
          onConfirm={() => modalProps.internalOnConfirm()}
          progression={progression}
          learnerName={learnerName}
        />
      ),
    });
  }, [triggerModal, progression, learnerName]);
}
